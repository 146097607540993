<template>
  <div class="system">
    <my-banner :banner="banner" :nav="nav" />
    <router-view />
  </div>
</template>

<script>
import { queryPhotoManageList } from "@/api/api.js";
export default {
  name: "system",
  data() {
    return {
      banner: "",
      nav: [
        {
          path: "/system/property",
          name: "物业服务",
        },
        {
          path: "/system/provide",
          name: "供热供水",
        },
        {
          path: "/system/preferred",
          name: "宜民优选",
        },
        {
          path: "/system/convenience",
          name: "便民服务",
        },
        {
          path: "/system/build",
          name: "工程建设",
        },
      ],
    };
  },
  created() {
    queryPhotoManageList({
      photoPage: "服务体系",
    }).then((res) => {
      this.banner = res.data.rows[0].photoPicture;
    });
  },
};
</script>
